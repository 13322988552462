import { getFromLocal } from "@/helpers/local.helper";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "landing",
    component: () => import("../views/LandingView.vue"),
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("../components/layouts/MainLayout.vue"),
    children: [
      {
        path: "jadwal",
        name: "jadwal",
        component: () => import("../views/jadwal/ListView.vue"),
      },
      {
        path: "daftar/submit",
        name: "submit",
        component: () => import("../views/pendaftaran/SubmitView.vue"),
      },
      {
        path: "daftar",
        name: "daftar",
        component: () => import("../views/pendaftaran/HomeView.vue"),
        meta: { requiresAuth: true },
        children: [
          {
            path: "form",
            name: "form",
            component: () => import("../views/pendaftaran/FormView.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "antrean",
            name: "antrean",
            component: () => import("../views/antrean/ListView.vue"),
            meta: { requiresAuth: true },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = getFromLocal("me");
  if (to.meta.requiresAuth && !isAuthenticated) {
    next("/menu/daftar/submit");
  } else if (to.path === "/menu/daftar/submit" && isAuthenticated) {
    next("/menu/daftar/form");
  } else {
    next();
  }
});

export default router;
