import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import '@/assets/css/style.css'
import '@/assets/css/swal.style.css'
import './assets/tailwind.css'

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import 'vue-cal/dist/vuecal.css';
import './assets/css/vuecal.style.css';

createApp(App).use(router).use(store).mount('#app')
